import React, { useState, useEffect } from 'react'
import _includes from 'lodash/includes'
import _ from 'lodash'
import styled, { css } from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { useBreakpoint } from 'src/styling/media'

import vars from 'src/styling/vars'
import media from 'src/styling/media'
import colors from 'src/styling/colors'

import GradientOverlay from 'src/components/HeaderGradient'
import Spacing from 'src/components/Spacing'
import { Link } from 'src/components/RouterDom'
import { getAssetSrc } from 'src/utility'

import { isCordova } from 'src/env'
import SavedHeart from 'src/components/SavedHeart'
import { PlusExclusiveBadge } from 'src/components/Badges'
import AssetIcon from 'src/components/AssetIcon'
import BannerPauseSvg from 'src/assets/BannerPause.svg'
import BannerPlaySvg from 'src/assets/BannerPlay.svg'
import { useInternationalisation } from 'src/context'
import Show from 'src/components/Show'

const Wrapper = styled.div`
  height: ${(props) => `${props.bannerHeight.sm}px`};
  ${media.greaterThan('md')`
    height: ${(props) => `${props.bannerHeight.md}px`}
  `};
  ${media.greaterThan('lg')`
    height: ${(props) => `${props.bannerHeight.lg}px`}
  `};
  ${media.lessThan('md')`
    ${(p) => (p.type === 'home-banner' ? '' : 'max-height: 100vw')};
  `}
  background-color: ${colors.primary};
  background-image: url(${(p) => p.src});
  background-size: cover;
  background-position: center center;
  position: relative;
-webkit-transform: translate3d(0,0,0);
`

const VideoContainer = styled.div`
  width: 100%;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  height: ${(props) => `${props.bannerHeight.sm}px`};
  ${media.greaterThan('md')`
    height: ${(props) => `${props.bannerHeight.md}px`}
  `};
  ${media.greaterThan('lg')`
    height: ${(props) => `${props.bannerHeight.lg}px`}
  `};
`

const VideoWrapper = styled.div`
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  height: ${(props) => `${props.bannerHeight.sm}px`};
  ${media.greaterThan('md')`
    height: ${(props) => `${props.bannerHeight.md}px`}
  `};
  ${media.greaterThan('lg')`
    height: ${(props) => `${props.bannerHeight.lg}px`}
  `};
  z-index: 0;
`

const Video = (props) => {
  const { className, src, srcExtension, height, link } = props
  const { translate } = useInternationalisation()
  let videoElement = React.createRef()
  const [paused, setPaused] = useState(false)

  useEffect(() => {
    const video = videoElement.current.firstElementChild
    if (paused !== video.paused) {
      if (paused) {
        video.pause()
      } else {
        video.play()
      }
    }
  }, [paused])

  const buttonSize = 40

  return (
    <React.Fragment>
      <ConditionalWrapper
        condition={link}
        wrapper={children => <StyledLink to={link}>{children}</StyledLink>}
      >
        <VideoContainer
          bannerHeight={height}
          ref={videoElement}
          dangerouslySetInnerHTML={{
            __html: `
            <video
              loop
              muted
              autoplay
              playsinline
              class='${className}'
              src='${src}'
              type='video/${srcExtension}'
            >
            </video>
          `
          }}
        />
      </ConditionalWrapper>
      <Controls>
      <PlayPauseVideoBannerButton
        onClick={() => setPaused((paused) => !paused)}
      >
        {paused ? (
          <BannerPlaySvg
            aria-label={translate('PLAY_LABEL')}
            height={buttonSize}
            width={buttonSize}
          />
        ) : (
            <BannerPauseSvg
              aria-label={translate('PAUSE_LABEL')}
              height={buttonSize}
              width={buttonSize}
            />
          )}
      </PlayPauseVideoBannerButton>
      </Controls>
    </React.Fragment>
  )
}

const VideoPlayer = styled(Video)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: ${colors.primary};
`

const TextBox = styled.div`
  height: 100%;
  ${media.forEach({
  values: vars.headerHeight,
  getStyle: (val) => `padding-top: ${val}px`
})}
  ${(p) =>
    p['data-noheader'] ? `padding-top: 0 !important;` : ''}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
`

const heroSizes = { sm: 60, md: 90, lg: 100 }
const titleSizes = { sm: 40, md: 60, lg: 75 }

const scaleText = sizes => ({ children }) => {
  let scale = 1
  const textLength = _.size(children)
  switch (true) {
    case textLength >= 50:
      scale = 2
      break;
    default:
      scale = 1;
      break;
  }
  return media.forEach({
    values: sizes,
    getStyle: (val) => `font-size: ${val / scale}px;`
  })
}

const Title = styled.h1`
  text-align: center;
  font-weight: bold;
  color: white;
  word-wrap: anywhere;
  font-size: 100px;
  ${(props) => scaleText(props.isHero ? heroSizes : titleSizes)}
  line-height: 1.33;
  text-shadow: 0 0 40px rgba(0, 0, 0, 0.25);
  ${(props) =>
    props.textTopMargin &&
    css`
      margin-top: ${props.textTopMargin}px;
    `}
  ${(props) =>
    !props.hasText &&
    css`
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0,0,0,0);
    `}
`

const Controls = styled.div`
position: absolute;
display: flex;
flex-direction: row-reverse;
width: 100%;
bottom: 0;
padding: 10px;
`
const PlayPauseVideoBannerButton = styled.button`
  color: white;
  opacity: 0.5;
  &:hover,
  &:active,
  &:focus {
    opacity: 1;
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
`

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

const renderImageBanner = ({
  src,
  height,
  text,
  noHeader,
  type,
  textTopMargin,
  link,
  isHero,
  savedMeta,
  isPlusExclusive,
  centreName
}) => {
  const {
    showSavedHeart,
    savedId,
    content_id,
    content_type,
    handleOnHeartClick
  } = _.pick(savedMeta, [
    'showSavedHeart',
    'savedId',
    'content_id',
    'content_type',
    'handleOnHeartClick'
  ])
  const { translate } = useInternationalisation()
  const bannerText = text || `${translate('DEFAULT_HOME_BANNER_TITLE', {centreName})}`
  return (
    <Wrapper src={src} bannerHeight={height} type={type}>
      {src && (
        <Helmet>
          <meta property='og:image' content={src} />
        </Helmet>
      )}
      <ConditionalWrapper
        condition={link}
        wrapper={children => <StyledLink to={link}>{children}</StyledLink>}
      >
      <TextBox data-noheader={noHeader}>
        <Title isHero={isHero} hasText={text} textTopMargin={textTopMargin}>{bannerText}</Title>
        {isPlusExclusive && <PlusExclusiveBadge size={20} transparent />}
        {showSavedHeart && isCordova && (
          <SavedHeart
            size={40}
            saved={savedId}
            handleOnHeartClick={() =>
              handleOnHeartClick({
                savedId,
                content_id,
                content_type
              })
            }
            top={vars.headerHeight.sm - 40}
          />
        )}
      </TextBox>
      </ConditionalWrapper>
      {noHeader ? null : <GradientOverlay />}
    </Wrapper>
  )
}

const renderVideoBanner = ({ src, srcExtension, height, link }) => {
  return (
    <VideoWrapper bannerHeight={height}>
      <VideoPlayer src={src} srcExtension={srcExtension} height={height} link={link}/>
      <GradientOverlay />
    </VideoWrapper>
  )
}

const hasExtension = (extension, formats) => _includes(formats, extension)

const getExtension = (src) =>
  /[.]/.exec(src) ? /[^.]+$/.exec(src)[0] : undefined

const getBannerHeight = (type) =>
  type === 'home-banner' ? vars.homepageBannerHeight : vars.pageBannerHeight

const PageBanner = (props) => {
  let {
    bannerMediaDesktop,
    src,
    text,
    type,
    noHeader,
    hide,
    textTopMargin,
    link,
    isHero = false,
    savedMeta,
    isPlusExclusive = false,
    centreName,
    bannerMediaMobile
  } = props

  if (hide) return <Spacing height={vars.headerHeight.sm + 45} />

  const { lt } = useBreakpoint()
  const asset = lt('header') && bannerMediaMobile ? bannerMediaMobile : bannerMediaDesktop

  if (asset) src = getAssetSrc(asset, { width: 2000 })

  const height = getBannerHeight(type)
  const srcExtension = getExtension(src)
  const isVideo = hasExtension(srcExtension, ['mp4', 'wmv', 'mov'])
  const isImage = hasExtension(srcExtension, ['png', 'jpg'])

  return (<React.Fragment>
    {!noHeader && isCordova && <Show lt='header'><Spacing height={vars.headerHeight.sm} /></Show>}
    {
      isVideo
        ? renderVideoBanner({ src, srcExtension, height, link })
        : renderImageBanner({
          src,
          height,
          text,
          noHeader,
          type,
          textTopMargin,
          link,
          isHero,
          savedMeta,
          isPlusExclusive,
          centreName
        })
    }</React.Fragment>)
}

export default PageBanner
